import { images } from "../../../../Assets/assetsHelper";
import "./MissionJudging.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display, fontSize, maxWidth } from "@mui/system";
import { Autocomplete, Box, Rating, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import TextArea from "../../../../Components/Atoms/Inputs/TextArea/TextArea";
import { toast } from "react-toastify";
import useSave from "../../../../Hooks/useSave";
import TextFieldInput from "../../../../Components/Atoms/Inputs/TextField/TextFieldInput";

const AttributeRating = ({ attribute, i, setScores, scores }) => {
  const [value, setValue] = useState(null);
  const [hover, setHover] = useState(-1);
  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: { xs: "5px" } }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  padding: { xs: "10px 10px 4px" },
                  fontSize: { xs: "15px", md: "18px" },
                  display: { xs: "block", md: "flex" },
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                {i + 1} - {attribute?.attributeName}
                <span className="scoreSmall">
                  {scores?.find(
                    (item) => item?.reportAttributeId == attribute?.id
                  )?.value ?? 0}
                  /{attribute?.maxValue}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Rating
                  className="rating"
                  name="hover-feedback"
                  value={
                    scores?.find(
                      (item) => item?.reportAttributeId == attribute?.id
                    )?.value
                  }
                  onChange={(event, newValue) => {
                    setScores([
                      ...scores?.filter(
                        (item) => item?.reportAttributeId != attribute?.id
                      ),
                      {
                        reportAttributeId: attribute?.id,
                        value:
                          attribute?.maxValue % 10 == 0
                            ? (attribute?.maxValue / 10) * newValue
                            : (attribute?.maxValue / 5) * newValue,
                      },
                    ]);
                  }}
                  icon={<CircleIcon fontSize="inherit" />}
                  emptyIcon={<TripOriginIcon fontSize="inherit" />}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  max={attribute?.maxValue % 10 == 0 ? 10 : 5}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function MissionJudging({
  loading,
  url,
  team,
  id,
  scores,
  setScores,
  note,
  setNote,
  handleSubmit,
  addLoading,
  setAward,
  award,
}) {
  return (
    <div className="mainContainer">
      {team && !loading ? (
        <div className="Cont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Name: </h4>
                  <h3>{team?.mission?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Number: </h4>
                  <h3>{team?.mission?.order}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Name: </h4>
                  <h3>{team?.team?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Code: </h4>
                  <h3>{team?.team?.teamId}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Category: </h4>
                  <h3>{team?.mission?.categorypName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group: </h4>
                  <h3>{team?.mission?.ageGroupName}</h3>
                </div>
              </div>
            </Box>
          </div>
          <Box
            sx={{
              width: "100%",
              // display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
              marginBottom: "0px",
              maxWidth: "1000px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "auto",
                marginBottom: "0px",
              }}
            >
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                Scoring Sheet
              </Typography>
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                Total:{" "}
                {scores?.map((item) => item?.value)?.reduce((a, b) => a + b, 0)}
                /100
              </Typography>
            </Box>

            <div
              className="greyBox"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              {team?.report?.groups?.map((group, i) => (
                <>
                  <Typography
                    variant={"h3"}
                    sx={{
                      fontSize: { xs: "17px", md: "22px" },
                      margin: "10px 0",
                      textAlign: "start",
                      fontWeight: "700",
                    }}
                  >
                    {i + 1} - {group?.groupName}
                  </Typography>
                  {group?.attributes?.map((attribute, i) => (
                    <AttributeRating
                      attribute={attribute}
                      i={i}
                      setScores={setScores}
                      scores={scores}
                    />
                  ))}
                </>
              ))}
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {team?.report?.groups?.length + 1} - Write Your Note:
              </Typography>
              <div style={{ margin: "0", width: "100%" }}>
                <TextArea
                  Required
                  name="text"
                  placeholder={"Enter your note about this team.."}
                  classes="textAreaInputFull"
                  label="Note"
                  newValue={note}
                  change={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </div>
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {team?.report?.groups?.length + 2} - Choose a special award for
                this team:
              </Typography>
              <div style={{ margin: "0", width: "100%" }}>
                <Autocomplete
                  options={team?.specialAwards ?? []}
                  getOptionLabel={(option) => option?.award}
                  filterSelectedOptions
                  name={"awards"}
                  className="choose"
                  value={award}
                  onChange={(e, newValue) => setAward(newValue)}
                  renderInput={(params) => (
                    <TextFieldInput
                      {...params}
                      placeholder="Special Award"
                      className={`Input`}
                      styles={{ width: "100%" }}
                      name={"awards"}
                      Required={false}
                    />
                  )}
                />
                {/* {error && <p className="error">{error}</p>} */}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <PrimaryButton
                  classBtn={"center addButton"}
                  btnType={"submit"}
                  text={"Submit"}
                  loading={addLoading}
                  click={handleSubmit}
                  style={{
                    textTransform: "capitalize",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: "var(--primary)",
                    maxWidth: "200px",
                  }}
                />
              </div>
            </div>
          </Box>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default MissionJudging;
