export const BaseURL = `https://stagingscoringapi.codechallenge-championship.org`;

export const loginURL = `${BaseURL}/scoring/login`;
export const logoutURL = `${BaseURL}/scoring/logout`;
export const userInfo = `${BaseURL}/scoring/loggedInInfo`;
export const categoriesURL = `${BaseURL}/scoring/categories`;
export const ageGroupsURL = `${BaseURL}/scoring/ageGroups`;
export const teamsURL = `${BaseURL}/scoring/teams`;
export const missionsURL = `${BaseURL}/scoring/missions`;
export const judgesURL = `${BaseURL}/scoring/judges`;
export const missionJudgingURL = `${BaseURL}/scoring/missionJudgings`;
export const activateURL = `${BaseURL}/activate`;
