import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { missionJudgingURL, missionsURL } from "../Hooks/URL";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import useFetchWhen from "../Hooks/useFetchWhen";
import MissionJudging from "../Templates/Home/Missions/MissionJudging/MissionJudging";
import { toast } from "react-toastify";

function MissionJudgingPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id, teamId } = useParams();

  const url = `${missionJudgingURL}/${id}/teams/${teamId}/forEvaluate`;
  const judgingURL = `${missionJudgingURL}/${id}/teams/${teamId}/scoringReport`;

  const [attributes, setAttributes] = useState({});
  const [team, setTeam] = useState({});
  // const { data, loading, error } = useFetch(url);
  const {
    data: teamData,
    loading: teamLoading,
    error: teamError,
  } = useFetch(url);

  // useEffect(() => {
  //   if (!loading && !error && data) {
  //     setAttributes(data?.data);
  //   }
  // }, [data, loading, error]);

  useEffect(() => {
    if (!teamLoading && !teamError && teamData) {
      setTeam(teamData?.data);
    }
  }, [teamData, teamLoading, teamError]);

  const navigate = useNavigate();
  const [scores, setScores] = useState([]);
  const [note, setNote] = useState("");
  const [award, setAward] = useState(null);
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: judgingURL,
    goSave,
    setGoSave,
    newObject: {
      nomination: { specialAwardId: award?.id },
      note: note,
      scoringReportAttributes: scores,
    },
  });

  if (saveState.dataRes.status == "success") {
    navigate(`/missions/${id}`, { replace: true });
  }

  useEffect(() => {
    if (team?.report)
      setScores(
        team?.report?.groups
          ?.map((group) =>
            group?.attributes?.map((attribute) => ({
              reportAttributeId: attribute?.id,
              value: 0,
            }))
          )
          ?.flat()
      );
  }, [team?.report]);

  const handleSubmit = () => {
    if (
      !scores?.map((item) => item?.value)?.some((item) => item == 0) &&
      note &&
      award
    )
      setGoSave(true);
    else if (scores?.map((item) => item?.value)?.some((item) => item == 0))
      toast.error("Please check all your inputs are not zeros", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    else if (!note)
      toast.error("Please write a note for this team", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    else if (!award)
      toast.error("Please choose a special award for this team", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    else
      toast.error(
        "Please check all your inputs are not zeros, note added, and special ward choosed",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
  };

  return (
    <MissionJudging
      attributes={attributes}
      role={contextData?.role}
      url={judgingURL}
      addLoading={addLoading}
      loading={teamLoading}
      team={team}
      id={id}
      scores={scores}
      setScores={setScores}
      note={note}
      setNote={setNote}
      handleSubmit={handleSubmit}
      setAward={setAward}
      award={award}
    />
  );
}

export default MissionJudgingPage;
