import { images } from "../../../../Assets/assetsHelper";
import "./JudgingReport.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display, fontSize, maxWidth } from "@mui/system";
import { Box, Rating, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import TextArea from "../../../../Components/Atoms/Inputs/TextArea/TextArea";
import { toast } from "react-toastify";
import useSave from "../../../../Hooks/useSave";

const AttributeRating = ({ attribute, i, setScores, scores }) => {
  const [value, setValue] = useState(null);
  const [hover, setHover] = useState(-1);
  return (
    <>
      <TableContainer component={Paper} sx={{ marginBottom: { xs: "5px" } }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  padding: { xs: "10px 10px 4px" },
                  fontSize: { xs: "15px", md: "18px" },
                  display: { xs: "block", md: "flex" },
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                }}
              >
                {i + 1} - {attribute?.attributeName}
                <span className="scoreSmall">
                  {attribute?.value}/{attribute?.maxValue}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Rating
                  className="rating"
                  readOnly
                  name="hover-feedback"
                  value={
                    attribute?.maxValue % 10 == 0
                      ? (attribute?.value / attribute?.maxValue) * 10
                      : (attribute?.value / attribute?.maxValue) * 5
                  }
                  icon={<CircleIcon color="#6a0505" fontSize="inherit" />}
                  emptyIcon={<TripOriginIcon fontSize="inherit" />}
                  max={attribute?.maxValue % 10 == 0 ? 10 : 5}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

function JudgingReport({ loading, url, report }) {
  return (
    <div className="mainContainer">
      {report && !loading ? (
        <div className="Cont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Name: </h4>
                  <h3>{report?.mission?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Number: </h4>
                  <h3>{report?.mission?.order}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Name: </h4>
                  <h3>{report?.team?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Team Code: </h4>
                  <h3>{report?.team?.teamId}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Category: </h4>
                  <h3>{report?.mission?.categoryName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group: </h4>
                  <h3>{report?.mission?.ageGroupName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Date: </h4>
                  <h3>{report?.dateTime?.split("T")?.at(0)}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Time: </h4>
                  <h3>{report?.dateTime?.split("T")?.at(1)}</h3>
                </div>
              </div>
            </Box>
          </div>
          <Box
            sx={{
              width: "100%",
              // display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
              marginBottom: "0px",
              maxWidth: "1000px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "auto",
                marginBottom: "0px",
              }}
            >
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                Scoring Sheet
              </Typography>
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "20px", md: "25px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                Total: {report?.totalScore}/{report?.maxTotalScore}
              </Typography>
            </Box>

            <div
              className="greyBox"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              {report?.reportGroups?.map((group, i) => (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      margin: "auto",
                      marginBottom: "5pxpx",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        fontSize: { xs: "17px", md: "22px" },
                        margin: "10px 0",
                        textAlign: "start",
                        fontWeight: "600",
                      }}
                    >
                      {i + 1} - {group?.groupName}
                    </Typography>
                    {/* <Typography
                      variant={"h3"}
                      sx={{
                        fontSize: { xs: "17px", md: "22px" },
                        margin: "5px 0",
                        textAlign: "center",
                        fontWeight: "600",
                        width: { xs: "100%", md: "auto" },
                      }}
                    >
                      Group Score:{" "}
                      <span style={{ fontWeight: "700" }}>
                        {group?.totalScore}/{group?.maxTotalScore}
                      </span>
                    </Typography> */}
                  </Box>
                  {group?.attributes?.map((attribute, i) => (
                    <AttributeRating
                      attribute={attribute}
                      i={i}
                      scores={attribute?.report}
                    />
                  ))}
                </>
              ))}
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "700",
                }}
              >
                {report?.reportGroups?.length + 1} - Judge's Note:
              </Typography>
              <div style={{ margin: "0", width: "100%" }}>
                <TextArea
                  readOnly
                  name="text"
                  placeholder={"Enter your note about this team.."}
                  classes="textAreaInputFull"
                  label="Note"
                  newValue={report?.note}
                />
              </div>
              <Typography
                variant={"h3"}
                sx={{
                  fontSize: { xs: "17px", md: "22px" },
                  margin: "10px 0",
                  textAlign: "start",
                  fontWeight: "500",
                }}
              >
                <span style={{ fontWeight: "700" }}>
                  {report?.reportGroups?.length + 2} - Special Award:
                </span>{" "}
                {report?.nomination?.specialAward?.award}
              </Typography>
            </div>
          </Box>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default JudgingReport;
